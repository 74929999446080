import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import CropPopup from "../../../components/CropPopup"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import { authAxios } from "../../../helpers/axios"
import { ErrorToast } from "../../../helpers/toasters"
import Select from "../../../components/Select"

export default function EditOrder({onClose,onSubmit,updateData,submitting})
{
    const [manageData,setManageData] = useState({buyerId:updateData.buyer.id,products:updateData.orderProducts})
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editOrder';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [products,setProducts] = useState([])
    const [buyers,setBuyers] = useState([])

    const addProduct = () => {
        setManageData((prev) => {
          let prevData = { ...prev, products: [...prev.products] };
          prevData.products.push({ productId: '', cartons: '' });
          return prevData;
        });
      };
      
      const removeProduct = (index) => {
        setManageData((prev) => {
          let prevData = { ...prev, products: [...prev.products] };
          prevData.products.splice(index, 1); // Remove item at specific index
          return prevData;
        });
      };
      

    const setItem = (type,index,value)=>
    {
        setManageData((prev)=>
        {
        let newData = {...prev}

        // let newBomData = newData.products[index]
        if(type === 'productId') newData.products[index].productId = value
        if(type === 'cartons') newData.products[index].cartons = value

        return newData;
        })

    }


    async function getProducts(){

        try 
        {
            // let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}&retailerId=${id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product?limit=150&offset=0&populate=true&buyerId=${manageData.buyerId}`)
            // console.log('salse',res.data.data)
            setProducts(res.data.rows)
            // setCount(res.data.totalCount)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    } 
    async function getBuyers(){

        try 
        {
            // let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}&retailerId=${id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/buyer?limit=150&offset=0&populate=true`)
            // console.log('salse',res.data.data)
            setBuyers(res.data.data)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    } 


    useEffect(()=>
    {
        getProducts()
    },[])
   
    // useEffect(()=>
    // {
    //     getProducts()
    // },[manageData.buyerId])


    return <><Popup submitting={submitting} size="sm" title={'Update Order'} submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData})} id={formName} className='w-full'>
            <div className="col-span-4">
                </div>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="">
                <p className="mb-4 font-medium text-sm">Products</p>
                {
                    manageData.products.map((item,index)=>
                {
                    return <ProductItem products={products} item={item} removeProduct={removeProduct} addProduct={addProduct} setItem={setItem} key={index} index={index} length={manageData.products.length}/>
                })
                }
            </div>
            </div>
        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}


const ProductItem = ({item,setItem,index,length,addProduct,removeProduct,products})=>
{
    return <div className="flex items-center relative mb-4">
        <div className="grid grid-cols-5 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px] overflow-hidden">
            <div className="col-span-3">
                <select disabled={item.id ? true : false} required type="text" placeholder="Product Name" className="w-full py-2 px-4 text-[13px] font-medium border-0 rounded-none border-r border-r-gray-300 outline-none active:ring-0 bg-transparent " value={item.productId} onChange={(e)=>setItem('productId',index,e.target.value)}>
                <option value={''}>Select Product</option>
                {
                    products.map((item,index)=>
                {
                    return <option key={index} value={item.id}>{item.name}</option>
                })
                }    
                </select>
            </div>
            <div className="col-span-2">
                <input required type="text" placeholder="No. of cartons" className="w-full py-2 px-4 text-[13px] font-medium" value={item.cartons} onChange={(e)=>setItem('cartons',index,e.target.value)}/>
            </div>
        </div>
        {index+1 === length ? <div className="ml-2 absolute right-0">
            <button type="button" onClick={()=>addProduct()}><IoAddCircle fontSize={32}/></button>
        </div> :  <div className="ml-2 absolute right-0"><button type="button" onClick={()=>removeProduct(index)}><IoRemoveCircle color="red" fontSize={32}/></button></div>}
    </div>
}