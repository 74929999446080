import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import CropPopup from "../../../components/CropPopup"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"
import { authAxios } from "../../../helpers/axios"
import { ErrorToast } from "../../../helpers/toasters"
import Select from "../../../components/Select"
import Loader from "../../../components/Loader"
import ModalImage from "react-modal-image"

export default function CreatePurchaseOrder({onClose,edit=false,onSubmit,updateData})
{
    // {bomId:'',bomName:'',productId:'',quantity:''}
    const [manageData,setManageData] = useState(!edit ? {name:'',sku:'',weight:'',purchaseProducts:[]} : {...updateData,name:updateData.name,sku:updateData.sku,weight:updateData.weight})
    const [cities,setCities] = useState([])
    const [routes,setRoutes] = useState([])
    const [distributors,setDistributors] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [products,setProducts] = useState([])
    const [buyers,setBuyers] = useState([])
    const [salesOrders,setSalesOrders] = useState([])
    const [salesOrderInfo,setSalesOrderInfo] = useState({})
    const [suppliers,setSuppliers] = useState([])
    const [boms,setBoms] = useState([])
    const [orderProducts,setOrderProducts] = useState([])
    const [loading,setLoading] = useState(true)

    const addProduct = () => {
        setManageData((prev) => {
          let prevData = { ...prev, products: [...prev.products] };
          prevData.products.push({ productId: '', quantity: '' });
          return prevData;
        });
      };
      
      const removeProduct = (index) => {
        setManageData((prev) => {
          let prevData = { ...prev, products: [...prev.products] };
          prevData.products.splice(index, 1); // Remove item at specific index
          return prevData;
        });
      };
      

      const setItem = (productId, bomId, type, value) => {
        setManageData((prev) => {
          // Clone the previous state to avoid direct mutation
          let newData = {...prev};
      
          // Find the product with the matching productId
          const product = newData.purchaseProducts.find(item => item.productId === productId);
      
          if (product) {
            // Find the BOM within the product that matches the bomId
            const bom = product.boms.find(b => b.bomId === bomId);
      
            if (bom) {
              // Update the relevant field based on the type
              if (type === 'supplierId') bom.supplierId = value;
              if (type === 'quantity') bom.quantity = value;
            }
          }
      
          return newData;
        });
      }


    async function getSalesOrderInfo(){

        try 
        {
            // let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}&retailerId=${id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/order/${manageData.salesOrderId}`)
            // console.log('salse',res.data.data)
            // const boms = data.flatMap(item => item.orderProducts.product.bom);

            const allBoms = res.data.orderProducts.flatMap(item => 
                item.product.bom.map(bom => ({
                //   ...bom,
                  productId: item.productId,
                  bomId: bom.id,
                  bomImage: bom.image,
                  bomName: bom.name,
                  supplierId: '',
                  orderId: item.id,
                  quantity: item.quantity
                }))
              );

            const groupedBoms = res.data.orderProducts.map(item => ({
                productId: item.productId,
                orderId: item.id,
                name:item.product.name,
                productImage:item.product.image,
                boms: item.product.bom.map(bom => ({
                  bomId: bom.id,
                  bomName: bom.name,
                  bomImage: bom.image,
                  supplierId: '',
                  quantityRequired:bom.shared === 1 ? Math.ceil(item.quantity/bom.quantity) : bom.shared === 2 ? Math.ceil(item.quantity*bom.quantity) :  item.quantity,
                  quantity: (bom.shared === 1 ? Math.ceil(item.quantity/bom.quantity) : bom.shared === 2 ? Math.ceil(item.quantity*bom.quantity) : item.quantity) - bom.stock > 0 ? Math.ceil(bom.shared === 1 ? item.quantity/bom.quantity : bom.shared === 2 ?  item.quantity*bom.quantity :  item.quantity) - bom.stock : 0,
                  stock:bom.stock
                }))
              }));
              
              setBoms(allBoms);
              setManageData(prev => ({...prev, purchaseProducts: groupedBoms}));
            // Extract Products with only id and name
            const products = res.data.orderProducts.map(item => ({
            id: item.product.id,
            name: item.product.name
            }));
            setOrderProducts(products)
            setSalesOrderInfo(res.data)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            ErrorToast(error.response.data.name)
        }
    } 
    async function getBuyers(){

        try 
        {
            // let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}&retailerId=${id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/buyer?populate=true`)
            // console.log('salse',res.data.data)
            setBuyers(res.data.data)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    } 
    
    async function getSuppliers(){

        try 
        {
            let query = `limit=${150}&offset=${0}&populate=true`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/supplier?${query}`)
            // console.log('salse',res.data.data)
            setSuppliers(res.data.data)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    } 
    async function getSalesOrders(){

        try 
        {
            // let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}&retailerId=${id}`;
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/order?purchaseCreated=false&populate=true`)
            // console.log('salse',res.data.data)
            setSalesOrders(res.data.orders)
            // setCount(res.data.count)
            // setSummary(res.data.summary)
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    } 


    useEffect(()=>
    {
        getBuyers()
        getSuppliers()
        getSalesOrders()
    },[])
   
    useEffect(()=>
    {
        getSalesOrderInfo()
    },[manageData.salesOrderId])


    const onSubmission =(e)=>
    {
        e.preventDefault(0)
        const bomsForSubmission = manageData.purchaseProducts.flatMap(product =>
            product.boms.map(bom => ({
              bomId: bom.bomId,
              supplierId: bom.supplierId,
              quantity: bom.quantity
            }))
          );
          onSubmit({salesOrderId:manageData.salesOrderId,purchaseProducts:bomsForSubmission})

    }


    return <><Popup size="xl" title={edit ? 'Update Purchase Order' : 'Create Purchase Order'} submitTitle={edit ? 'Update' : 'Create'} onClose={onClose} formName={formName}>
        {!loading ? <form onSubmit={onSubmission} id={formName} className='w-full'>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
            <div className="grid grid-cols-4 gap-x-3 gap-y-0 w-full">
                <div className="col-span-1">
                    <label>Sales Order</label>
                    {/* <Select options={salesOrders} customLabel={'orderId'} customValue={'id'} placeholder={'Select Sales Order ID'} value={manageData.salesOrderId} setValue={(value)=>setManageData(data=>({...data,salesOrderId:value}))}/> */}
                        <select placeholder={'Select Sales Order ID'} value={manageData.salesOrderId} onChange={(e)=>setManageData(data=>({...data,salesOrderId:e.target.value}))}>
                            {
                                salesOrders.map((orderItem,index)=>
                                {
                                    return <option key={index} value={orderItem.id}>{`${orderItem.orderId} - ${orderItem.buyer.name}`}</option>
                                })
                            }
                        </select>
                </div>
                <div className="col-span-1">
                    <label>Expected Delivery Date</label>
                    <Input type='date' placeholder={'Enter Expected Delivery Date'} value={manageData.expectedDeliveryDate} setValue={(value)=>setManageData(data=>({...data,expectedDeliveryDate:value}))}/>
                </div>
            </div>
            <div className="border-t border-t-gray-200 pt-6 my-4">
                <p className="mb-4 font-medium text-sm">Products</p>
                {
                    manageData.purchaseProducts.map((item,index)=>
                {
                    return <ProductItem salesOrderInfo={salesOrderInfo} boms={boms} suppliers={suppliers} products={orderProducts} item={item} removeProduct={removeProduct} addProduct={addProduct} setItem={setItem} key={index} index={index} length={manageData.purchaseProducts.length} manageData={manageData}/>
                })
                }
            </div>
            </div>
        </form> : <Loader/>}
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}


const ProductItem = ({item,manageData,setItem,index,length,addProduct,removeProduct,products=[],suppliers=[],salesOrderInfo,boms=[]})=>
{
    return <div className="flex items-center relative mb-4">
        <div className="block grid-cols-5 w-full border border-gray-200 bg-[#fcfcfc] rounded-[4px] overflow-hidden">
            <div className="col-span-2 py-2 px-2">
                <div className="flex items-center j">
                    <div className="image-preview mr-2 ">
                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.productImage}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.productImage}`} alt={item.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                    </div>
                    <p className="font-medium mb-2 text-xs">{item.name}</p>
                </div>
                {
                    item.boms.map((it,index)=>
                    {
                        return <BomItem productId={item.productId} salesOrderInfo={salesOrderInfo} boms={boms} suppliers={suppliers} products={products} item={it} removeProduct={removeProduct} addProduct={addProduct} setItem={setItem} key={index} index={index}/>
                    })
                }
                
            </div>
        </div>
    </div>
}

const BomItem = ({productId,item,setItem,index,length,addProduct,removeProduct,products=[],suppliers=[],salesOrderInfo,boms=[]})=>
{
    return <div className="block items-center relative mb-4 ml-2  ">
        <div className="grid grid-cols-12 w-full mr-10 ">
        <div className="col-span-3"><label>Supplier</label></div>
        <div className="col-span-2"><label>BOM</label></div>
        <div className="col-span-2"><label>Qty Required</label></div>
        <div className="col-span-2"><label>In Stock</label></div>
        <div className="col-span-2"><label>Order Qty</label></div>
            </div>

        <div className="flex">
            <div className="image-preview mr-2 ">
                <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.bomImage}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.bomImage}`} alt={item.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
            </div>
        <div className="grid grid-cols-12 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px]">
            <div className="col-span-3">
                <select required type="text" placeholder="Product Name" className="w-full py-2 px-4 text-[13px] font-medium border-0 rounded-none border-r border-r-gray-300 outline-none active:ring-0 bg-transparent " value={item.supplierId} onChange={(e)=>setItem(productId,item.bomId,'supplierId',e.target.value)}>
                <option value={''}>Select Supplier</option>
                {
                    suppliers.map((item,index)=>
                {
                    return <option key={index} value={item.id}>{item.name}</option>
                })
                }    
                </select>
            </div>
            <div className="col-span-2">
                <select disabled={true} required type="text" placeholder="Product Name" className="w-full py-2 px-4 text-[13px] font-medium border-0 rounded-none border-r border-r-gray-300 outline-none active:ring-0 bg-transparent " value={item.bomId} onChange={(e)=>setItem('bomId',index,e.target.value)}>
                <option value={''}>Select BOM</option>
                {
                    boms.map((item,index)=>
                {
                    return <option key={index} value={item.bomId}>{item.bomName}</option>
                })
                }    
                </select>
            </div>
            <div className="col-span-2">
                <input required type="text" disabled={true} placeholder="Quantity" className="w-full py-2 px-4 text-[13px] font-medium border-r border-r-gray-300" value={item.quantityRequired}/>
            </div>
            <div className="col-span-2">
                <input required type="text" disabled={true} placeholder="Quantity" className="w-full py-2 px-4 text-[13px] font-medium border-r border-r-gray-300" value={(item.stock)}/>
            </div>
            <div className="col-span-2">
                <input required type="text" placeholder="Quantity" className="w-full py-2 px-4 text-[13px] font-medium" value={item.quantity} onChange={(e)=>setItem(productId,item.bomId,'quantity',e.target.value)}/>
            </div>
        </div>
        </div>
        {/* {index+1 < length ? <div className="ml-2 absolute right-0">
            <button type="button" onClick={()=>addProduct()}><IoAddCircle fontSize={32}/></button>
        </div> :  <div className="ml-2 absolute right-0"><button type="button" onClick={()=>removeProduct(index)}><IoRemoveCircle color="red" fontSize={32}/></button></div>} */}
         <div className="ml-2 absolute top-5 right-0 z-10"><button type="button" onClick={()=>removeProduct(index)}><IoRemoveCircle color="red" fontSize={32}/></button></div>
    </div>
}