import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Map from "../../../components/Map"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import { authAxios } from "../../../helpers/axios"
import ImageInput from "../../../components/ImageInput"
import MultiSelect from 'react-select';
import CropPopup from "../../../components/CropPopup"
import { COUNTRIES, RETAILER_IMAGE_BASE_URL } from "../../../helpers/constants"
import { IoAdd, IoAddCircle, IoRemoveCircle } from "react-icons/io5"

export default function ManageBom({onClose,edit=false,onSubmit,updateData})
{
    const [manageData,setManageData] = useState({id:updateData.id,name:updateData.name,quantity:updateData.quantity,image:updateData.image,shared:updateData.shared,rate:updateData.rate})
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'editRetailer';
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState(null)

    const addBom = () => {
        setManageData((prev) => {
          let prevData = { ...prev, boms: [...prev.boms] };
          prevData.boms.push({ name: '', quantity: '' });
          return prevData;
        });
      };
      
      const removeBom = (index) => {
        setManageData((prev) => {
          let prevData = { ...prev, boms: [...prev.boms] };
          prevData.boms.splice(index, 1); // Remove item at specific index
          return prevData;
        });
      };
      

    const setItem = (type,index,value)=>
    {
        setManageData((prev)=>
        {
        let newData = {...prev}

        // let newBomData = newData.boms[index]
        if(type === 'name') newData.boms[index].name = value
        if(type === 'quantity') newData.boms[index].quantity = value
        if(type === 'shared') newData.boms[index].shared = value

        return newData;
        })

    }

    const onImageChange = async(event) => {
        if (event.target.files && event.target.files[0]) {
            setManageData(data=>({...data,image:null}))
            // setShowCrop(true)
            // console.log(URL.createObjectURL(event.target.files[0]))
            // let blobImage = await dataURItoBlob(event.target.files[0])
            // console.log(blobImage)
            setImage(URL.createObjectURL(event.target.files[0]));
            setCroppedImage(event.target.files[0])
            // setImage(event.target.files[0]);
        }
        }



    return <><Popup title={'Update BOM'} submitTitle={'Update'} onClose={onClose} formName={formName}>
        <form onSubmit={(e)=>onSubmit(e,{...manageData},croppedImage)} id={formName} className='w-full'>
            <div className="col-span-4">
                    <ImageInput image={manageData.image && manageData.image!=='' ? `${manageData.image}` : image} setImage={onImageChange} />
                </div>
                <div className="block grid-cols-2 gap-x-3 gap-y-0 w-full">
                <div className="flex items-center relative mb-4">
                    <div className="grid grid-cols-7 w-full mr-10 border border-gray-200 bg-[#fcfcfc] rounded-[4px] overflow-hidden">
                        <div className="col-span-3">
                            <input type="text" placeholder="Material Name" className="w-full py-2 px-4 text-[13px] font-medium border-r border-r-gray-300" value={manageData.name} onChange={(e)=>setManageData(prev=>({...prev,name:e.target.value}))}/>
                        </div>
                        <div className="col-span-2">
                            <select className="w-full py-2 px-4 text-[13px] font-medium focus:shadow-none focus:ring-0 border-0 border-r border-r-gray-200 rounded-none" value={manageData.shared} onChange={(e)=>setManageData(prev=>({...prev,shared:e.target.value}))}>
                                <option value={0}>Need Qty of</option>
                                <option value={1}>Shared With</option>
                                <option value={2}>Shared Weight</option>
                                </select>
                        </div>
                        <div className="col-span-1 border-r border-r-gray-200">
                            <input type="text" placeholder="Qty." className="w-full py-2 px-4 text-[13px] font-medium" value={manageData.quantity} onChange={(e)=>setManageData(prev=>({...prev,quantity:e.target.value}))}/>
                        </div>
                        <div className="col-span-1">
                            <input type="text" placeholder="Rate" className="w-full py-2 px-4 text-[13px] font-medium" value={manageData.rate} onChange={(e)=>setManageData(prev=>({...prev,rate:e.target.value}))}/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </Popup>
    {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null}
    </>
}
