import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import SearchInput from '../../../components/SearchInput'
import { authAxios } from '../../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import ManageOrder from '../../order/components/ManageOrder'
import { ErrorToast, InfoToast } from '../../../helpers/toasters'
import { LIMIT } from '../../../helpers/constants'
import RightIcon from '../../../images/right.svg'
import SortIcon from '../../../images/sort.svg'
import FilterIcon from '../../../images/filter.svg'
import FilterActiveIcon from '../../../images/filteractive.svg'
import FilterOrder from './RetailerOrderFilter'
import { getDateFormat, getTimeFormat } from '../../../helpers/utils'
import AddProduct from './AddProduct'
import ManageBom from './ManageBom'
import ModalImage from 'react-modal-image'

export default function BuyerProducts({id,showAddProduct,setShowAddProduct}) {

    const [searchText,setSearchText] = useState('')
    const [reps,setOrders] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})
    const [showManageBom,setShowManageBom] = useState({status:false,edit:null})
    const [vieworder,setVieworder] = useState(false)
    const [sort,setSort] = useState('-createdOn')

    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [summary,setSummary] = useState({})
    const [filters,setFilters] = useState({routeId:null,cityId:null,status:null,salesRepId:null,retailerId:id,startDate:null,endDate:null})
    const [showFilters,setShowFilters] = useState(false)
    const [expandedRow, setExpandedRow] = useState(null);
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})

    async function getProducts(){

        try 
        {
            console.log('filters',filters)
            let query = `limit=${LIMIT}&offset=${offset}&search=${searchText}&populate=true&orderBy=${sort}&buyerId=${id}`;
            // query+=``
            // if(filters.cityId) query+=`&cityId=${filters.cityId.id}`
            // if(filters.routeId) query+=`&routeId=${filters.routeId.id}`
            // if(filters.distributorId) query+=`&distributorId=${filters.distributorId.id}`
            // if(filters.salesRepId) query+=`&salesRepId=${filters.salesRepId.id}`
            // if(filters.teamId) query+=`&salesTeamId=${filters.teamId.id}`
            // if(filters.status) query+=`&orderStatus=${filters.status}`
            // if(filters.startDate) query+=`&startDate=${`${new Date(filters.startDate).getDate()}-${new Date(filters.startDate).getMonth()+1}-${new Date(filters.startDate).getFullYear()}`}`
            // if(filters.endDate) query+=`&endDate=${`${new Date(filters.endDate).getDate()}-${new Date(filters.endDate).getMonth()+1}-${new Date(filters.endDate).getFullYear()}`}`
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/product?${query}`)
            // console.log('salse',res.data.data)
            setOrders(res.data.rows)
            setCount(res.data.totalCount)
            // setSummary(res.data.summary)
        } catch (error) {
            console.log(error)
            toast('Error getting orders')
        }
    } 

    const onFilterSubmit = (filters)=>
    {
        try 
        {
            setFilters({...filters})
            setCount(5)
            setOffset(0)
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }

    const clearFilter = ()=>
    {
        try 
        {
            setFilters({routeId:null,cityId:null,status:null,distributorId:null,retailerId:null,teamId:null,salesRepId:null,startDate:null,endDate:null})
            setShowFilters(false)
        } catch (error) {
            toast('Order Filtered')
        }
    }



    useEffect(()=>
    {
        getProducts();
    },[offset,searchText,filters,sort])


    const onSubmit = async(e,data,croppedImage)=>
    {
        try 
        {
            e.preventDefault()
            let imageRes;
            if(croppedImage)
            {
                let formData = new FormData();
                formData.append('file',croppedImage)
                imageRes = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/upload`,formData,{                headers: {
            'content-type': 'multipart/form-data'}})
            } 

            let res;
            if(showAddProduct.edit)
            {
                res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/product/${showAddProduct.item.id}`,{image:imageRes ? imageRes.data : data.image,name:data.name,sku:data.sku,weight:data.weight,quantityPerCarton:data.quantityPerCarton,rate:data.rate})
                await getProducts()
            }
            else
            {
                res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/product`,{...data,buyerId:id,image:imageRes.data}) 
                await getProducts()
                InfoToast('Product Added')
            }
            setShowAddProduct({edit:null,status:false})
        } catch (error) {
            ErrorToast(error.response.data.name)
        }
    }
 
    const onBomUpdate = async(e,data,croppedImage)=>
    {
        try 
        {
            e.preventDefault()
            let imageRes;
            if(croppedImage)
            {
                let formData = new FormData();
                formData.append('file',croppedImage)
                imageRes = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/upload`,formData,{headers: {
            'content-type': 'multipart/form-data'}})
            } 

            // let res;
            // if(showCreate.edit)
            // {
                let updateData = croppedImage ? {...data,image:imageRes.data} : {...data};
            // let base64Image = await getBase64Image(data.retailerShopImage)
            let res = await authAxios.put(`${process.env.REACT_APP_BASE_URL}/product/bom/${data.id}`,updateData) 
            await getProducts()
            InfoToast('BOM Updated')
            setShowManageBom({edit:null,status:false})
        } catch (error) {
            console.log('error',error)
            ErrorToast('Error Updating Image')
        }
    }


    const onOrderCreate = async(e,manageData,products)=>
    {
        try 
        {
            e.preventDefault()
            let orderProducts = products.map((item,index)=>
            {
                if(item.orderQty && item.orderQty > 0 ) return {itemProductId:item.id,itemQty:item.orderQty}
            })
            let res = await authAxios.post(`${process.env.REACT_APP_BASE_URL}/orders`,{...manageData,orderItems:orderProducts})  
            getProducts()
            setShowManage(false)
            InfoToast('Order Created')
        } catch (error) {
            toast('Order Creating Failed')
            
        }
    }

    const onSortPress = (type)=>
    {
        if (sort === type) {
            if(sort === `-${type}`) setSort(type)
            else setSort(`-${type}`);
          } else {
            setSort(type);
          }
    }
    
    const toggleRow = (id) => {
        setExpandedRow(expandedRow === id ? null : id);
      };


  return (
    <div className='w-full overflow-scroll flex flex-col'>
    <div>
          <div className='h-full flex justify-between items-stretch border-b border-[#e3e3e3] bg-white'>
            <div className='flex'>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search order'} label={'Search order'} />
                <div className='flex w-full border-r items-center h-full justify-center text-center'>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset > 0) {
                            setOffset(offset - LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                    </div>
                    <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                    <div
                        className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                        onClick={() => {
                        if (offset + LIMIT < count) {
                            setOffset(offset + LIMIT);
                        }
                        }}
                    >
                        <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                    </div>
                    </div>
            </div>
            <div className='flex justify-end h-[100%] self-stretch items-center px-4 border-l border-[#e3e3e3] hover:bg-[#f6f6f6] cursor-pointer' onClick={()=>setShowFilters(true)}>
            {(filters.cityId || filters.routeId || filters.distributorId || filters.teamId || filters.salesRepId || filters.startDate || filters.endDate) ? <img src={FilterActiveIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> : <img src={FilterIcon} className={`w-[24px] h-[24px]`} alt='Filter' /> }
            </div>
        </div>
        </div>
        <div className=' bg-white w-full flex-1 flex overflow-scroll'>
            <table className=' table-auto w-full flex-1'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                

                    <td><p onClick={()=>onSortPress('name')} className='cursor-pointer flex items-center hover:text-black'>Product Name {sort === 'name' || sort === '-name'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('weight')} className='cursor-pointer flex items-center hover:text-black'>Product Weight {sort === 'weight' || sort === '-weight'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    <td><p onClick={()=>onSortPress('buyer')} className='cursor-pointer flex items-center hover:text-black'>Buyer {sort === 'buyer' || sort === '-buyer'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    
                    <td><p onClick={()=>onSortPress('bom')} className='cursor-pointer flex items-center hover:text-black'>BOMs {sort === 'bom' || sort === '-bom'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>
                    <td><p onClick={()=>onSortPress('quantityPerCarton')} className='cursor-pointer flex items-center hover:text-black'>Qty Per Carton {sort === 'quantityPerCarton' || sort === '-quantityPerCarton'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    {/* <td><p onClick={()=>onSortPress('createdOn')} className='cursor-pointer flex items-center hover:text-black'>Ordered On {sort === 'createdOn' || sort === '-createdOn'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td> */}

                    <td><p onClick={()=>onSortPress('qty')} className='cursor-pointer flex items-center hover:text-black'>Action {sort === 'qty' || sort === '-qty'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td>

                    {/* <td><p onClick={()=>onSortPress('status')} className='cursor-pointer flex items-center hover:text-black'>Status {sort === 'status' || sort === '-status'? sort.charAt(0)==='-' ? <img src={SortIcon} className='rotate-[180deg] h-[16px] w-[16px] ml-1'/> : <img src={SortIcon} className='h-[16px] w-[16px] ml-1'/> : null}</p></td> */}
                    {/* <td><p>Action</p></td> */}
                    </tr>
                </thead>
                <tbody>
                    {
                        reps.map((item,index)=>
                        {
                            return <><tr onClick={()=>toggleRow(item.id)}>
                                <td className='capitalize'>
                                    <div className='flex items-center'>
                                    <div className="image-preview mr-2 ">
                                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${item.image}`} alt={item.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                    </div>
                                        <p className='text-sm font-medium my-0'>{item.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-medium my-0'>{item.weight} Gms</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.buyer?.name}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                  <p className='text-sm font-medium my-0'>{parseInt(item.bom?.length)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                  <p className='text-sm font-medium my-0'>{parseInt(item.quantityPerCarton)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <button className='btn-xs' onClick={()=>setShowAddProduct({status:true,edit:true,item:item})}>Edit Product</button>
                                    </div>
                                </td>
                            </tr>
                            {expandedRow === item.id && (
                            <tr>
                                <td colSpan={5} className='px-0 bg-gray-50'>
                                <div className='w-full'>
                                    <table>
                                        <tbody>
                                            {
                                                item.bom.map((it,i)=>
                                                {
                                                    return <tr key={i} className='py-0 border-b-0'>
                                                        <td colSpan={2} className='py-0 pl-16'>
                                                        <div className="image-preview mr-2 ">
                                        <ModalImage small={`${process.env.REACT_APP_STATIC_URL}/${it.image}`} medium={`${process.env.REACT_APP_STATIC_URL}/${it.image}`} alt={it.name} className="rounded-full h-[32px] w-[32px] image-preview inline-block"/>
                                    </div>
                                                            <p className='text-xs font-medium'>{it.name}</p>
                                                        </td>
                                                        {/* <td>
                                                        </td> */}
                                                        <td><p className='text-xs font-medium'>{it.shared === 1 ? 'Shared BOM' : it.shared === 2 ? 'Shared Weight' :  'Unique BOM'}</p></td>
                                                        <td><p className='text-xs font-medium'>{it.shared === 1 ? `Shared: ${it.quantity}` : it.shared === 2 ? `Shared Weight: ${it.quantity} Gms` :  `Qty. ${it.quantity}`}</p></td>
                                                        <td><p className='text-xs font-medium'>In Stock:{it.stock}</p></td>
                                                        <td className='capitalize'>
                                <div>
                                        <button className='btn-xs' onClick={()=>setShowManageBom({status:true,edit:it})}>Edit BOM</button>
                                    </div>
                                </td>
                                                        {/* <td>In Stock:{it.stock}</td> */}
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                </td>
                            </tr>
                            )}
                            </>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* <div className=' flex justify-between items-stretch border-t border-[#e3e3e3] bg-white w-full'>
            <div className='flex px-6 py-2 justify-between w-full'>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total/Cancelled/Pending/Finished-{count}/{summary.cancelledOrders}/{summary.pendingOrders}/{summary.finishedOrders}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Points - {summary.totalPoints}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Weight - {(summary.totalWeight/1000).toFixed(2)}Kgs</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Amount - {summary.totalAmount}</p>
                <p className='text-[13px] text-gray-600 font-medium my-0'>Total Qty - {summary.totalQty}</p>
            </div>
        </div> */}
        { showAddProduct.status ? <AddProduct onClose={setShowAddProduct} onSubmit={onSubmit} edit={showAddProduct.edit} updateData={showAddProduct.item}/> : null}
        { showManageBom.status ? <ManageBom updateData={showManageBom.edit} onClose={setShowManageBom} onSubmit={onBomUpdate} edit={true}/> : null}
        {/* { vieworder ? <ViewOrder getProducts={getProducts} onClose={setVieworder} id={vieworder}/> : null} */}
        { showFilters ? <FilterOrder onSubmit={onFilterSubmit} id={vieworder} filters={filters} onClose={setShowFilters} clearFilter={clearFilter}/> : null}
    </div>
  )
}

